// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-post-js": () => import("../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-page-js": () => import("../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-project-js": () => import("../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-binarios-en-js": () => import("../src/pages/binarios.en.js" /* webpackChunkName: "component---src-pages-binarios-en-js" */),
  "component---src-pages-binarios-js": () => import("../src/pages/binarios.js" /* webpackChunkName: "component---src-pages-binarios-js" */),
  "component---src-pages-binaris-ca-js": () => import("../src/pages/binaris.ca.js" /* webpackChunkName: "component---src-pages-binaris-ca-js" */),
  "component---src-pages-blog-ca-js": () => import("../src/pages/blog.ca.js" /* webpackChunkName: "component---src-pages-blog-ca-js" */),
  "component---src-pages-blog-js": () => import("../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-en-js": () => import("../src/pages/contact.en.js" /* webpackChunkName: "component---src-pages-contact-en-js" */),
  "component---src-pages-contacte-ca-js": () => import("../src/pages/contacte.ca.js" /* webpackChunkName: "component---src-pages-contacte-ca-js" */),
  "component---src-pages-contacto-js": () => import("../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-index-ca-js": () => import("../src/pages/index.ca.js" /* webpackChunkName: "component---src-pages-index-ca-js" */),
  "component---src-pages-index-en-js": () => import("../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projectes-ca-js": () => import("../src/pages/projectes.ca.js" /* webpackChunkName: "component---src-pages-projectes-ca-js" */),
  "component---src-pages-projects-en-js": () => import("../src/pages/projects.en.js" /* webpackChunkName: "component---src-pages-projects-en-js" */),
  "component---src-pages-proyectos-js": () => import("../src/pages/proyectos.js" /* webpackChunkName: "component---src-pages-proyectos-js" */),
  "component---src-pages-serveis-ca-js": () => import("../src/pages/serveis.ca.js" /* webpackChunkName: "component---src-pages-serveis-ca-js" */),
  "component---src-pages-services-en-js": () => import("../src/pages/services.en.js" /* webpackChunkName: "component---src-pages-services-en-js" */),
  "component---src-pages-servicios-js": () => import("../src/pages/servicios.js" /* webpackChunkName: "component---src-pages-servicios-js" */)
}

