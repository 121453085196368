module.exports = [{
      plugin: require('../node_modules/gatsby-wordpress-inline-images/gatsby-browser.js'),
      options: {"plugins":[],"baseUrl":"databinaria.binariademos.com","protocol":"http","postTypes":["post"],"quality":72,"maxWidth":1440,"backgroundColor":"white","withWebp":true},
    },{
      plugin: require('../node_modules/gatsby-wordpress-inline-images/gatsby-browser.js'),
      options: {"plugins":[],"baseUrl":"databinaria.binariademos.com/ca","protocol":"http","postTypes":["post"],"quality":72,"maxWidth":1440,"backgroundColor":"white","withWebp":true},
    },{
      plugin: require('../node_modules/gatsby-wordpress-inline-images/gatsby-browser.js'),
      options: {"plugins":[],"baseUrl":"databinaria.binariademos.com/en","protocol":"http","postTypes":["post"],"quality":72,"maxWidth":1440,"backgroundColor":"white","withWebp":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-N3RQ3D","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Binaria, agencia digital","short_name":"Binaria","start_url":"/","background_color":"#FFFFFF","theme_color":"#FFFFFF","display":"standalone","icon":"src/images/vostok.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
